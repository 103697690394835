import Content from './Content.js';
import Footer from './Footer.js';

function App() {
        
    return (    
        <>
            <Content />
            <Footer />
        </>  
    );
}

export default App;