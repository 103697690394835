import { useState, useEffect, useLayoutEffect } from 'react';
import '../styles/Content.css';
import WhitelistSpots from './WhitelistSpots.js';
import WhitelistNames from './WhitelistNames.js';

function Content() {

    const [data, setData] = useState([]);
    const [burn, setBurn] = useState([]);
    const [confirmedWLSpots, setConfirmedWLSpots] = useState([]);
    const sheetId = "1pJQA4LV8gfs2Y-RkLOLM2WReflXTOCI7VpmuT4NxV3w";
    let userList = [];
    let user = [];
    let burnTotal;

    useWindowSize();

    function addToList(wlList) {

        let startIndex = 1;

        try {
            for (let charIndex = 1; charIndex <= wlList.length; charIndex++) {
            
                if (wlList[charIndex] === ",") {
                    user.push(wlList.substring(startIndex, charIndex));
                    startIndex = charIndex+1;
                }
                else if ((wlList[charIndex] === "\n")) {
                    user.push(wlList.substring(startIndex, charIndex));
                    userList.push(user);
                    startIndex = charIndex+1;
                    user = [];
                }
                if (charIndex === wlList.length) {
                    user.push(wlList.substring(startIndex, charIndex));
                    userList.push(user);
                    user = [];
                }
            }

            setData(userList);
            getConfirmedSpots();

        } catch (error) {
            console.error("An error occured while trying to populate the whilelist:\n" + error)
        }     
    }

    const getList = async() => {

        const sheetName = encodeURIComponent("Confirmed Spots");
        const sheetUrl = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:csv&sheet=${sheetName}`;

        try {
            await fetch(sheetUrl)
            .then((response) => response.text())
            .then((csvText) => {
                addToList(csvText.replace(/"DISCORD ID","TWITTER","PHASE","QUANTITY"/g, "").replace(/,""/g, "").replace(/"/g, ""));
            });
        } catch (error) {
            console.error("An error occurred while fetching the whitelist names:\n" + error);
        }
    }

    const getBurnTotal = async() => {

        const sheetName = encodeURIComponent("Total Burned");
        const sheetUrl = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:csv&sheet=${sheetName}`;

        try {
            await fetch(sheetUrl)
            .then((response) => response.text())
            .then((csvText) => {
                burnTotal = csvText.replace(/"TOTAL LEGENDS BURNED"/g, "").replace(/,""/g, "").replace(/"/g, "");
                setBurn(burnTotal);
            });
        } catch (error) {
            console.error("An error occurred while fetching the burn total:\n" + error);
        }
    }

    const getConfirmedSpots = () => {

        let count = 0;
            
        for(let x = 0; x < userList.length; x++) {
            count += parseInt(userList[x][3]);
        }

        setConfirmedWLSpots(count);
    }

    useEffect(() => {
        getList();
        getBurnTotal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // Resize WL Names box
    function useWindowSize() {

        useLayoutEffect(() => {
            
            function setNamesBoxHeight() {
                document.getElementById("wl_names").style.height = document.getElementById("wl_spots").clientHeight - 20 + "px";
                document.getElementsByClassName("names")[0].style.height = document.getElementById("wl_names").style.height;
            }

            window.addEventListener('resize', setNamesBoxHeight);
            setNamesBoxHeight();
            
            return () => window.removeEventListener('resize', setNamesBoxHeight);
        });
    }
    
    return (
        <div className="Content">
            
            <div className="heading">
                <div style={{position: "relative", top: "0.02em"}}>CELESTIALS&nbsp; BY &nbsp;</div>
                <a href="https://steadystack.io/" title="The Official Steady Stack Website" style={{display: "flex"}}> 
                    <svg className="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 143.305 31.091">
                        <path d="M98.718,230.09c6.372,0,7.017-2.915,7.017-4.627,0-4.437-5.019-4.609-8.767-4.737-2.232-.076-4.014-.138-4.014-1.075,0-.467.229-.847,
                            1.373-.847h5.415c1.767,0,2.019,1.016,2.019,1.479h3.955c0-1.73-.645-5.282-7.036-5.282H96.035c-6.39,0-7.035,2.915-7.035,4.65,0,4.389,4.9,4.538,8.632,
                            4.653,2.307.071,4.172.127,4.172,1.16,0,.44-.293.842-1.4.842H94.992c-1.749,0-2.019-1.034-2.019-1.478H89.041c0,1.712.645,5.263,7.013,
                            5.263Zm21.9-15.09H105.658v3.8h5.452v11.282h3.932V218.8h5.58Zm16.9,0v3.739h-12.24v2.092h12.24v3.529h-12.24v1.986h12.24v3.517L144.909,215H149.7l7.371,
                            14.884V215h11.1c2.664,0,7.846.613,7.846,7.543s-5.182,7.543-7.846,7.543v0h-11.1v0h-4.313l-1.52-3.273h-7.892l-1.519,3.273H121.349V215Zm9.782,3.314,2.271,
                            4.924h-4.559Zm24.546,4.233c0-1.9-.5-3.739-2.6-3.739h-8.221v7.5h8.221C171.35,226.31,171.848,224.469,171.848,222.547Zm13.88,2.046L193.286,215h-5.058l-4.477,
                            6.17L179.3,215h-5.058l7.557,9.593v5.492h3.932Zm-79.993,16.873c0,1.712-.648,4.625-7.017,4.625H96.054c-6.367,0-7.013-3.547-7.013-5.259h3.932c0,.444.27,1.478,
                            2.019,1.478h5.415c1.1,0,1.4-.4,1.4-.842,0-1.032-1.865-1.09-4.172-1.16C93.9,240.194,89,240.045,89,235.655c0-1.734.645-4.65,7.035-4.65h2.646c6.391,0,7.036,3.551,
                            7.036,5.281h-3.955c0-.462-.251-1.478-2.019-1.478H94.328c-1.144,0-1.373.38-1.373.847,0,.938,1.781,1,4.014,1.076C100.715,236.859,105.734,237.031,105.735,
                            241.466Zm14.886-10.46H105.658v3.8h5.452v11.282h3.932V234.809h5.58Zm8.843,0,7.471,15.085h-4.413L131,242.818h-7.891l-1.52,3.273h-4.413l7.493-15.085h4.792Zm-.123,
                            8.238-2.271-4.925-2.289,4.925Zm12.8,3.066c-2.1,0-2.6-1.84-2.6-3.762,0-1.9.5-3.739,2.6-3.739h5.415a2.345,2.345,0,0,1,2.5,
                            2.366h4.184c-.645-5.643-5.287-6.169-7.763-6.169H143.23c-2.664,0-7.846.59-7.846,7.543s5.182,7.543,7.846,7.543h3.245c2.477,0,7.118-.526,7.763-6.17h-4.184c-.293,
                            1.588-.938,2.389-2.5,2.389Zm30.708-11.3-6.409,7.543,6.409,7.543h-5.182L163,240.429h-4.1v5.662h-3.932V231.006H158.9v5.666H163l4.665-5.666Zm30.806,
                            15.085h15.8l-8.443-31.082h-15.8ZM232.3,226.063,229.3,215H213.509l3.005,11.063Zm-34.155,8.964,3.005,11.063h-15.8l-3.005-11.063Z" 
                            transform="translate(-89 -215)" fill="var(--ss-orange)" fillRule="evenodd">
                        </path>   
                    </svg>
                </a>  
            </div>
            <div className="subheading">
                Total Legends Burned:&nbsp;<span className="burn_num">{burn}</span>
            </div>       

            <div className='container'>
                <div className="wl_box">

                    <div className="border-top">
                        <div className='border-left'></div>
                        <div className='border-right'></div>
                    </div>

                    <div className='title_box'>
                        <div className="description_text">Confirmed Spots: &nbsp;<span className="burn_num">{confirmedWLSpots}</span><span style={{color: "rgba(252,252,252,.3)" }}>&nbsp;/&nbsp;333</span></div>
                        <div className="border-bottom"></div>    
                    </div>

                    <div className="content_panel" id="wl_spots">
                        <WhitelistSpots mintList={data} />
                    </div>
                </div>

                <div className="wl_box">

                    <div className="border-top">
                        <div className='border-left'></div>
                        <div className='border-right'></div>
                    </div>

                    <div className='title_box'>
                        <div className="description_text">The Ascended</div>
                        <div className="border-bottom"></div> 
                    </div>

                    <div className="content_panel" id="wl_names">
                        <WhitelistNames mintList={data} />
                    </div>
                </div>        
            </div>
        </div>
    );
}

export default Content;