import '../styles/WhitelistSpots.css';

function WhitelistSpots({mintList}) {

    const data =[];
    let total = 0;
    
    // Collect total quantity from mintList
    for(let x = 0; x < mintList.length; x++) {
        total += parseInt(mintList[x][3]);

        // Add color filled spots for each registered minter to grid
        if(mintList[x][3] > 1) {

            for(let y = 0; y < mintList[x][3]; y++) {
                data.push({"key": <div className='filled' name={mintList[x][0]}></div> });
            }
        }
        else {
            data.push({"key": <div className='filled' name={mintList[x][0]}></div> });
        }
    }
    
    //Add remaining unfilled spots to grid
    for(let x = total; x < 333; x++) {
        data.push({"key": <div className='empty'></div> })
    }

    return (
        <div className='grid'>
            {data.map(function(x, y) {
                return (<span className='whitelist' key={y}>{x.key}</span>)
            })}
        </div>
    );
}

export default WhitelistSpots;