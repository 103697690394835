import '../styles/WhitelistNames.css';

function WhitelistNames({mintList}) {
    
    const data = [];

    for(let x = 0; x < mintList.length; x++) {

        try{
            // wrote it this way for SEO; <a> tags without a valid href hurt the score
            if(mintList[x][1].includes("http")) {
                data.push({"key":
                    <div style={{position: "relative"}}>
                        <a title="" href={mintList[x][1]} target={"rel=noopener"} style={{textDecoration: "none"}}>
                            <span className="whitelist_name">
                                {mintList[x][0]}
                                <div className="x_icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.154 15.12" height="16">
                                        <path d="M281.539,167.31l6.237,8.339-6.276,6.78h1.413l5.495-5.936,4.44,5.936h4.807l-6.588-8.808,5.842-6.311H295.5l-5.06,5.467-4.089-5.467Zm2.077,1.04h2.208l9.751,13.038h-2.208Z" transform="translate(-281.5 -167.31)" fill="rgba(0, 0, 0, 0.87)"></path>
                                    </svg>
                                </div>
                            </span>
                        </a>
                    </div>
                });
            }
            else {
                data.push({"key":
                    <div style={{position: "relative"}}>
                        <div style={{textDecoration: "none"}}>
                            <span className="whitelist_name">
                                {mintList[x][0]}
                                <div className="x_icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.154 15.12" height="16">
                                        <path d="M281.539,167.31l6.237,8.339-6.276,6.78h1.413l5.495-5.936,4.44,5.936h4.807l-6.588-8.808,5.842-6.311H295.5l-5.06,5.467-4.089-5.467Zm2.077,1.04h2.208l9.751,13.038h-2.208Z" transform="translate(-281.5 -167.31)" fill="rgba(0, 0, 0, 0.87)"></path>
                                    </svg>
                                </div>
                            </span>
                        </div>
                    </div>
                });
            }
            

        } catch(error) {
            console.error("An error occurred while attempting to create the names list:\n" + error);
        }
    }

    return (
        <div className="names">
            {data.map(function(x, y){
                return (<span className="name" key={y}>{x.key}</span>)        
            })}
        </div>
    );
}

export default WhitelistNames;